import { Entry, Order, Stash } from "stashify-models/types";
import { object, string } from "yup";

const EntrySchema = object().shape({
  stashId: string().trim().required(),
});
export const validateEntry = (entry: Entry) => EntrySchema.isValidSync(entry);

const StashSchema = object().shape({
  title: string().trim().required(),
});
export const validateStash = (stash: Stash) => StashSchema.isValidSync(stash);

const OrderSchema = object().shape({
  item: string().trim().required(),
});
export const validateOrder = (order: Order) => OrderSchema.isValidSync(order);
