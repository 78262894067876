import { Option } from "option-t/cjs/PlainOption";
import { createErr, createOk, Result } from "option-t/cjs/PlainResult";
import { Action, AsyncAction } from "overmind";
import { Entry, Order, Stash, User } from "stashify-models/types";
import { parseFirebaseUser } from "../utils";
import { NotificationType } from "./state";

// TODO
// @ts-ignore
let entriesUnsubscribe = null;
// @ts-ignore
let orderUnsubscribe = null;
// @ts-ignore
let stashUnsubscribe = null;

const changeAuthState: Action<{ user: Option<User> }, void> = (
  { state },
  { user },
) => {
  if (user.ok) {
    state.user.data = user.val;
  } else {
    state.user.data = null;
  }
  if (state.app.isInitialized === false) state.app.isInitialized = true;
};

const doSignIn: AsyncAction<void, boolean> = async ({
  state,
  effects,
  actions,
}) => {
  state.app.isLoading = true;

  const result = await effects.user.signIn();

  state.app.isLoading = false;

  if (result.ok) {
    const user = parseFirebaseUser(result.val);
    state.user.data = user;
    actions.data.subscribe();

    actions.app.notification.show({
      message: "Successfully signed in",
      type: "success",
    });
  } else {
    actions.app.notification.show({
      message: "Failed to sign in",
      type: "error",
    });
  }

  return result.ok;
};

const doSignOut: AsyncAction = async ({ state, effects, actions }) => {
  // TODO
  // unsubscribe

  state.app.isLoading = true;

  // @ts-ignore
  if (orderUnsubscribe) orderUnsubscribe();
  // @ts-ignore
  if (stashUnsubscribe) stashUnsubscribe();
  // @ts-ignore
  if (entriesUnsubscribe) entriesUnsubscribe();

  await effects.user.signOut();
  state.user.data = null;
  state.app.isLoading = false;

  actions.app.notification.show({
    message: "Successfully signed out",
    type: "success",
  });
};

export const user = {
  changeAuthState,
  doSignIn,
  doSignOut,
};

export const app: {
  notification: {
    show: Action<{ message: string; type: NotificationType }, void>;
    close: Action;
  };
  confirmDialog: {
    open: Action<{ onConfirm: () => any }, void>;
    onCancel: Action;
    onConfirm: Action;
  };
  bottomDrawer: {
    toggle: Action<boolean, void>;
  };
} = {
  notification: {
    show: ({ state }, payload) => {
      state.app.notification.show = true;
      state.app.notification.message = payload.message;
      state.app.notification.type = payload.type;
    },
    close: ({ state }) => {
      state.app.notification.show = false;
    },
  },
  confirmDialog: {
    open: ({ state }, payload) => {
      state.app.confirmDialog = {
        open: true,
        onConfirm: payload.onConfirm,
      };
    },
    onCancel: ({ state }) => {
      state.app.confirmDialog.open = false;
    },
    onConfirm: ({ state }) => {
      state.app.confirmDialog.onConfirm();
      state.app.confirmDialog.open = false;
    },
  },
  bottomDrawer: {
    toggle: ({ state }, payload) => {
      state.app.bottomDrawer.open = payload;
    },
  },
};

export const data: {
  subscribe: AsyncAction;
  entry: {
    add: AsyncAction<{ entry: Entry }, Result<null, null>>;
    update: AsyncAction<{ entry: Entry }, Result<null, null>>;
    delete: AsyncAction<{ entry: Entry }, Result<null, null>>;
  };
  stash: {
    add: AsyncAction<{ stash: Stash }, Result<null, null>>;
    update: AsyncAction<{ stash: Stash }, Result<null, null>>;
    delete: AsyncAction<{ stash: Stash }, Result<null, null>>;
  };
  orders: {
    add: AsyncAction<{ order: Order }, Result<null, null>>;
    update: AsyncAction<{ order: Order }, Result<null, null>>;
    delete: AsyncAction<{ order: Order }, Result<null, null>>;
  };
} = {
  subscribe: async ({ actions, state, effects }) => {
    const user = state.user.data;
    if (!user) return;

    entriesUnsubscribe = effects.data.entries.subscribe(user.uid, (result) => {
      if (!result.ok) {
        actions.app.notification.show({
          message: "Failed to load entries",
          type: "error",
        });
        return;
      } else {
        console.log("entries", result.val.length);
        state.entries.data = result.val;
      }
      if (state.entries.isLoading) state.entries.isLoading = false;
    });

    stashUnsubscribe = effects.data.stash.subscribe(user.uid, (stash) => {
      state.stash.data = stash;
      if (state.stash.isLoading) state.stash.isLoading = false;
    });

    orderUnsubscribe = effects.data.orders.subscribe(user.uid, (orders) => {
      state.orders.data = orders;
      if (state.orders.isLoading) state.orders.isLoading = false;
    });
  },
  entry: {
    add: async ({ state, actions, effects }, payload) => {
      // TODO
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.entries.add(
        state.user.data.uid,
        payload.entry,
      );

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully created entry",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to create entry",
          type: "error",
        });
        return createErr(null);
      }
    },
    update: async ({ state, actions, effects }, payload) => {
      // TODO
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.entries.update(payload.entry);

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully updated entry",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to update entry",
          type: "error",
        });
        return createErr(null);
      }
    },
    delete: async ({ state, actions, effects }, payload) => {
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.entries.remove(payload.entry);

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully deleted entry",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to delete entry",
          type: "error",
        });
        return createErr(null);
      }
    },
  },
  stash: {
    add: async ({ state, actions, effects }, payload) => {
      // TODO
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.stash.add(
        state.user.data.uid,
        payload.stash,
      );

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully created stash",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to create stash",
          type: "error",
        });
        return createErr(null);
      }
    },
    update: async ({ state, actions, effects }, payload) => {
      // TODO
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.stash.update(payload.stash);

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully updated stash",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to update stash",
          type: "error",
        });
        return createErr(null);
      }
    },
    delete: async ({ state, actions, effects }, payload) => {
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.stash.remove(payload.stash);

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully deleted stash",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to delete stash",
          type: "error",
        });
        return createErr(null);
      }
    },
  },
  orders: {
    add: async ({ state, actions, effects }, payload) => {
      // TODO
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.orders.add(
        state.user.data.uid,
        payload.order,
      );

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully created order",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to create order",
          type: "error",
        });
        return createErr(null);
      }
    },
    update: async ({ state, actions, effects }, payload) => {
      // TODO
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.orders.update(payload.order);

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully updated order",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to update order",
          type: "error",
        });
        return createErr(null);
      }
    },
    delete: async ({ state, actions, effects }, payload) => {
      if (!state.user.data) return createErr(null);

      state.app.isLoading = true;

      const resp = await effects.data.orders.remove(payload.order);

      state.app.isLoading = false;

      if (resp.ok) {
        actions.app.notification.show({
          message: "Successfully deleted order",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed to delete order",
          type: "error",
        });
        return createErr(null);
      }
    },
  },
};
