import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { inject } from "@vercel/analytics";
import { createOvermind } from "overmind";
import { Provider } from "overmind-react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import "./index.css";
import { SentryClient } from "./services/Sentry";
import { TrackingClient } from "./services/Tracking";
import { config } from "./store";

inject();
SentryClient.initialize();
TrackingClient.initialize();

const store = createOvermind(config, {
  devtools: false,
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider value={store}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </Provider>,
);
