import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { createErr, createOk } from "option-t/cjs/PlainResult";
import { config } from "./config";

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const firebaseApp = firebase.initializeApp(config);

export const db = firebase.firestore();
firebase.firestore().enablePersistence({ synchronizeTabs: true });

export const auth = firebaseApp.auth();

export const signIn = async () => {
  try {
    const { user } = await firebaseApp
      .auth()
      .signInWithPopup(googleAuthProvider);
    if (!user) return createErr(null);
    return createOk(user);
  } catch (error) {
    // TODO: log error
    return createErr(error);
  }
};

export const signOut = async () => {
  try {
    await firebaseApp.auth().signOut();
    return createOk(null);
  } catch (error) {
    // TODO: log error
    return createErr(error);
  }
};
