import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useStore } from "../../store";

export const AuthenticatedRoute: React.FC<RouteProps> = ({
  children,
  ...rest
}: any) => {
  const {
    state: {
      user: { isAuthenticated },
    },
  } = useStore();
  return (
    <Route
      {...rest}
      render={() => (isAuthenticated ? children : <Redirect to="/login" />)}
    />
  );
};
