import { css } from "@emotion/css";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useStore } from "../../store";

export const FloatingActionButton = () => {
  const { actions } = useStore();
  return (
    <div
      className={css`
        position: fixed;
        bottom: 64px;
        right: 64px;
        @media (max-width: 960px) {
          bottom: 32px;
          right: 32px;
        }
      `}
    >
      <Fab
        color="primary"
        size="medium"
        onClick={() => actions.app.bottomDrawer.toggle(true)}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};
