import { createNone, createSome, Option } from "option-t/cjs/PlainOption";
import { createErr, createOk, Result } from "option-t/cjs/PlainResult";
import { Entry, Order, Stash, User } from "stashify-models/types";
import * as firebaseAPI from "../services/Firebase";
import { parseFirebaseUser } from "../utils";

export const user = {
  get: async (): Promise<Option<User>> =>
    new Promise((resolve) => {
      try {
        const unsubscribe = firebaseAPI.auth.onAuthStateChanged((resp) => {
          unsubscribe();
          if (!resp) return resolve(createNone());
          const user = parseFirebaseUser(resp);
          return resolve(createSome(user));
        });
      } catch (error) {
        console.log(error);
        debugger;
        return resolve(createNone());
      }
    }),
  signIn: async () => firebaseAPI.signIn(),
  signOut: async () => firebaseAPI.signOut(),
};

export const data = {
  entries: {
    subscribe: (
      uid: string,
      callback: (result: Result<Entry[], any>) => void,
    ) => {
      // TODO: avoid reloading all entries on every change
      const unsubscribe = firebaseAPI.db
        .collection("entry")
        .where("uid", "==", uid)
        .orderBy("createdAt", "desc")
        .limit(200)
        .onSnapshot(
          (snapshot) => {
            const items: Entry[] = [];
            snapshot.forEach((doc) => {
              const { uid, ...rest } = doc.data();
              // @ts-ignore
              items.push({
                ...rest,
                id: doc.id,
              });
            });
            callback(createOk(items));
          },
          (error) => {
            callback(createErr(error));
            console.log(error);
            debugger;
          },
        );
      return unsubscribe;
    },
    add: async (uid: string, entry: Entry) => {
      try {
        const { id, ...rest } = entry;
        const response = await firebaseAPI.db.collection("entry").add({
          ...rest,
          uid,
        });
        return createOk(response);
      } catch (error) {
        return createErr(error);
      }
    },
    update: async (entry: Entry) => {
      try {
        const { id, ...rest } = entry;
        await firebaseAPI.db
          .collection("entry")
          .doc(entry.id)
          .update({
            ...rest,
          });
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
    remove: async (entry: Entry) => {
      try {
        await firebaseAPI.db.collection("entry").doc(entry.id).delete();
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
  },
  stash: {
    subscribe: (uid: string, callback: (stash: Stash[]) => void) => {
      const unsubscribe = firebaseAPI.db
        .collection("stash")
        .where("uid", "==", uid)
        .orderBy("createdAt", "desc")
        .onSnapshot((snapshot) => {
          const items: Stash[] = [];
          snapshot.forEach((doc) => {
            const { uid, ...rest } = doc.data();
            // @ts-ignore
            items.push({
              ...rest,
              id: doc.id,
            });
          });
          callback(items);
        });
      return unsubscribe;
    },
    add: async (uid: string, stash: Stash) => {
      try {
        const { id, ...rest } = stash;
        const response = await firebaseAPI.db.collection("stash").add({
          ...rest,
          uid,
        });
        return createOk(response);
      } catch (error) {
        return createErr(error);
      }
    },
    update: async (stash: Stash) => {
      try {
        const { id, ...rest } = stash;
        await firebaseAPI.db
          .collection("stash")
          .doc(stash.id)
          .update({
            ...rest,
          });
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
    remove: async (stash: Stash) => {
      try {
        await firebaseAPI.db.collection("stash").doc(stash.id).delete();
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
  },
  orders: {
    subscribe: (uid: string, callback: (orders: Order[]) => void) => {
      const unsubscribe = firebaseAPI.db
        .collection("orders")
        .where("uid", "==", uid)
        .orderBy("orderedAt", "desc")
        .onSnapshot((snapshot) => {
          const items: Order[] = [];
          snapshot.forEach((doc) => {
            const { uid, ...rest } = doc.data();
            // @ts-ignore
            items.push({
              ...rest,
              id: doc.id,
            });
          });
          callback(items);
        });
      return unsubscribe;
    },
    add: async (uid: string, order: Order) => {
      try {
        const { id, ...rest } = order;
        const response = await firebaseAPI.db.collection("orders").add({
          ...rest,
          uid,
        });
        return createOk(response);
      } catch (error) {
        return createErr(error);
      }
    },
    update: async (order: Order) => {
      try {
        const { id, ...rest } = order;
        await firebaseAPI.db
          .collection("orders")
          .doc(order.id)
          .update({
            ...rest,
          });
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
    remove: async (order: Order) => {
      try {
        await firebaseAPI.db.collection("orders").doc(order.id).delete();
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
  },
};
