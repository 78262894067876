import {
  format,
  formatDistance,
  formatDistanceToNow,
  parseISO,
} from "date-fns";
import { createNone, createSome } from "option-t/esm/PlainOption";

export const formatDate = (
  date: string,
  formatStr: string = "E, dd MMM yyyy, p",
) => {
  try {
    const formattedString = format(parseISO(date), formatStr);
    return createSome(formattedString);
  } catch {
    return createNone();
  }
};

export const formatDateFromNow = (date: string) => {
  try {
    const formattedString = formatDistanceToNow(parseISO(date), {
      includeSeconds: true,
      addSuffix: true,
    });
    return createSome(formattedString);
  } catch {
    return createNone();
  }
};

export const formatDateDistance = (startDate: string, endDate: string) => {
  try {
    const formattedString = formatDistance(
      parseISO(startDate),
      parseISO(endDate),
    );
    return createSome(formattedString);
  } catch {
    return createNone();
  }
};
