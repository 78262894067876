import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useStore } from "../../store";

export const UnauthenticatedRoute: React.FC<RouteProps> = ({
  children,
  ...rest
}: any) => {
  const {
    state: { user },
  } = useStore();
  return (
    <Route
      {...rest}
      render={() => (user.isAuthenticated ? <Redirect to="/" /> : children)}
    />
  );
};
