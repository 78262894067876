import { css } from "@emotion/css";
import Container from "@material-ui/core/Container";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Entry } from "stashify-models/types";
import { useStore } from "../../store";
import { EntryFormLite } from "../EntryFormLite";

export const BottomDrawer = () => {
  const {
    state: {
      stash,
      app: {
        bottomDrawer: { open },
      },
    },
    actions,
  } = useStore();

  const onSubmit = async (entry: Entry) => {
    await actions.data.entry.add({ entry });
    actions.app.bottomDrawer.toggle(false);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => actions.app.bottomDrawer.toggle(false)}
      onOpen={() => actions.app.bottomDrawer.toggle(true)}
      disableSwipeToOpen
    >
      <Container
        maxWidth="sm"
        className={css`
          padding-top: 16px;
          padding-bottom: 16px;
        `}
      >
        <EntryFormLite stash={stash.data} onSubmit={onSubmit} />
      </Container>
    </SwipeableDrawer>
  );
};
