import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { lazy, Suspense } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { AppFooter } from "./components/AppFooter";
import { AppHeader } from "./components/AppHeader";
import { AppSnackbar } from "./components/AppSnackbar";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import { BottomDrawer } from "./components/BottomDrawer";
import { ConfirmDialog } from "./components/ConfirmDialog";
import { FloatingActionButton } from "./components/FloatingActionButton";
import { FullPageLoader } from "./components/FullPageLoader";
import { FullPageLoadingIndicator } from "./components/FullPageLoadingIndicator";
import { ScrollToTop } from "./components/ScrollToTop";
import { UnauthenticatedRoute } from "./components/UnauthenticatedRoute";
import { useStore } from "./store";
import { theme, useStyles } from "./theme";

const HomePage = lazy(() => import("./pages/HomePage"));
const AccountPage = lazy(() => import("./pages/AccountPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const LogoutPage = lazy(() => import("./pages/LogoutPage"));
const EntriesPage = lazy(() => import("./pages/EntriesPage"));
const EntriesEditPage = lazy(() => import("./pages/EntriesEditPage"));
const EntriesNewPage = lazy(() => import("./pages/EntriesNewPage"));
const StashPage = lazy(() => import("./pages/StashPage"));
const StashNewPage = lazy(() => import("./pages/StashNewPage"));
const StashEditPage = lazy(() => import("./pages/StashEditPage"));
const StashEntriesPage = lazy(() => import("./pages/StashEntriesPage"));
const OrdersPage = lazy(() => import("./pages/OrdersPage"));
const OrdersNewPage = lazy(() => import("./pages/OrdersNewPage"));
const OrdersEditPage = lazy(() => import("./pages/OrdersEditPage"));

export const App = () => {
  const classes = useStyles();
  const { state } = useStore();

  return (
    <>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ScrollToTop />
          <AppHeader />
          <main className={classes.content}>
            <Suspense fallback={<FullPageLoader />}>
              {state.app.isInitialized ? (
                <Switch>
                  <Route path="/" exact>
                    <HomePage />
                  </Route>
                  <UnauthenticatedRoute path="/login" exact>
                    <LoginPage />
                  </UnauthenticatedRoute>
                  <AuthenticatedRoute path="/logout" exact>
                    <LogoutPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/account" exact>
                    <AccountPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/entries" exact>
                    <EntriesPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/entries/new" exact>
                    <EntriesNewPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/entries/:id" exact>
                    <EntriesEditPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/stash" exact>
                    <StashPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/stash/new" exact>
                    <StashNewPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/stash/:id" exact>
                    <StashEditPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/stash/:id/entries" exact>
                    <StashEntriesPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/orders" exact>
                    <OrdersPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/orders/new" exact>
                    <OrdersNewPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/orders/:id" exact>
                    <OrdersEditPage />
                  </AuthenticatedRoute>
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              ) : (
                <FullPageLoader />
              )}
            </Suspense>
            <BottomDrawer />
          </main>
          <footer>
            <AppFooter />
          </footer>
          <ConfirmDialog />
          <AppSnackbar />
          <FullPageLoadingIndicator />
          {state.user.isAuthenticated && <FloatingActionButton />}
        </ThemeProvider>
      </Router>
    </>
  );
};
