import { derived } from "overmind";
import { Entry, Order, Stash, User } from "stashify-models/types";

export type NotificationType = "success" | "error" | "warning" | "info";

export type StateApp = {
  isInitialized: boolean;
  isLoading: boolean;
  notification: {
    show: boolean;
    message: string;
    type: NotificationType;
  };
  confirmDialog: {
    open: boolean;
    onConfirm: () => any;
  };
  bottomDrawer: {
    open: boolean;
  };
};

export type StateUser = {
  isAuthenticated: boolean;
  data: User | null;
};

export type StateData = {
  entries: Entry[];
};

export type State = {
  app: StateApp;
  user: StateUser;
  entries: {
    isLoading: boolean;
    data: Entry[];
  };
  stash: {
    isLoading: boolean;
    data: Stash[];
  };
  orders: {
    isLoading: boolean;
    data: Order[];
  };
};

export const state: State = {
  app: {
    isInitialized: false,
    isLoading: false,
    notification: {
      show: false,
      message: "",
      type: "info",
    },
    confirmDialog: {
      open: false,
      onConfirm: () => {},
    },
    bottomDrawer: {
      open: false,
    },
  },
  user: {
    isAuthenticated: derived((user: StateUser) => user.data !== null),
    data: null,
  },
  entries: {
    isLoading: true,
    data: [],
  },
  stash: {
    isLoading: true,
    data: [],
  },
  orders: {
    isLoading: true,
    data: [],
  },
};
