import { isToday, parseISO } from "date-fns";
import firebase from "firebase/app";
import { Option, createNone, createSome } from "option-t/esm/PlainOption";
import { Entry, Order, Stash, User } from "stashify-models/types";
import { v4 as uuidv4 } from "uuid";

// TODO: validate user?
export const parseFirebaseUser = (user: firebase.User): User => {
  return {
    uid: user.uid,
    displayName: user.displayName || "undefined",
    email: user.email || "undefined",
    photoURL: user.photoURL,
  };
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

// Entry
export const generateNewEntry = (stashId: string = ""): Entry => ({
  id: uuidv4(),
  stashId,
  notes: null,
  createdAt: new Date().toISOString(),
});

// ORDER
export const generateOrderObj = (): Order => ({
  id: uuidv4(),
  orderedAt: new Date().toISOString(),
  status: 0,
  seller: "",
  store: "",
  item: "",
  amount: "",
  price: 0,
  note: null,
  tracking: null,
  arrivedAt: null,
  createdAt: new Date().toISOString(),
});

// STASH
export const generateStashObj = (): Stash => ({
  id: uuidv4(),
  title: "",
  stock: null,
  notes: null,
  createdAt: new Date().toISOString(),
});

export const getStashById = (stashList: Stash[], id: string): Option<Stash> => {
  const result = stashList.find((stash) => stash.id === id) || null;
  return result ? createSome(result) : createNone();
};

export const filterEntriesByToday = (entries: Entry[]): Entry[] => {
  return entries.filter((entry) => isToday(parseISO(entry.createdAt)));
};
