import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";
import { useHistory } from "react-router-dom";
import { APP_NAME } from "../../config";
import { useStore } from "../../store";

const useStyles = makeStyles((theme) => ({
  title: {
    letterSpacing: 0.75,
    fontWeight: "bold",
  },
  menu: {
    display: "flex",
    marginLeft: "auto",
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export const AppHeader = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    state: { app, user },
  } = useStore();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Button onClick={() => history.push("/")}>
            <Typography className={classes.title}>{APP_NAME}</Typography>
          </Button>
          {app.isInitialized && (
            <div className={classes.menu}>
              {user.data === null ? (
                <Button onClick={() => history.push("/login")}>login</Button>
              ) : (
                <>
                  <Button onClick={() => history.push("/entries")}>
                    entries
                  </Button>
                  <Button onClick={() => history.push("/stash")}>stash</Button>
                  <Button onClick={() => history.push("/orders")}>
                    orders
                  </Button>
                  <IconButton
                    color="inherit"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    {user.data.photoURL ? (
                      <Avatar
                        alt={user.data.displayName}
                        src={user.data.photoURL}
                        className={classes.avatar}
                      />
                    ) : (
                      <Avatar className={classes.avatar}>
                        <PersonIcon fontSize="small" />
                      </Avatar>
                    )}
                  </IconButton>
                </>
              )}
            </div>
          )}
          <Menu
            anchorEl={anchorEl}
            transformOrigin={{ horizontal: "right", vertical: "center" }}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                history.push("/account");
              }}
            >
              account
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                history.push("/logout");
              }}
            >
              logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
};
