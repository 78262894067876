import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useStore } from "../../store";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export const FullPageLoadingIndicator = () => {
  const classes = useStyles();
  const {
    state: {
      app: { isLoading },
    },
  } = useStore();

  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      {isLoading && <CircularProgress color="inherit" />}
    </Backdrop>
  );
};
